<template>
    <section>
        <div class="container">
            <TitleBar title="Envanter Kayıtları" :modi="modi" />

            <b-field class="columns box" label="Filtre Alanları" grouped group-multiline>
                <b-field class="column is-4" label="ID">
                    <b-input v-model="stocks.filters.id"></b-input>
                </b-field>
                <b-field class="column is-4" label="Malzeme Sınıfı">
                    <b-select
                        placeholder="Seçiniz.."
                        v-model="stocks.filters.material.class"
                        expanded
                    >
                        <option value="Hammadde">Hammadde</option>
                        <option value="Yarı Mamül">Yarı Mamül</option>
                        <option value="Mamül">Mamül</option>
                        <option value="Ticari">Ticari</option>
                        <option value="Diğer">Diğer</option>
                    </b-select>
                </b-field>
                <b-field class="column is-4" label="Malzeme Kodu">
                    <b-input v-model="stocks.filters.material.code"></b-input>
                </b-field>
                <b-field class="column is-4" label="Malzeme Açıklaması">
                    <b-input v-model="stocks.filters.material.name"></b-input>
                </b-field>
                <b-field class="column is-4" label="Ana Grup">
                    <b-input v-model="stocks.filters.material.maingroup"></b-input>
                </b-field>
                <b-field class="column is-4" label="Alt Grup">
                    <b-input v-model="stocks.filters.material.subgroup"></b-input>
                </b-field>
            </b-field>

            <b-field class="columns box mt-2" label="Envanter Tablosu" grouped group-multiline>
                <b-field class="column is-12" grouped group-multiline>
                    <b-button
                        class="column"
                        type="is-info is-light"
                        icon-left="magnify"
                        label="Ara"
                        @click="stocks_read"
                    />
                    <b-button
                        class="column ml-1"
                        type="is-success is-light"
                        icon-left="plus"
                        label="Yeni"
                        @click="stocks_new"
                    />
                    <b-button
                        class="column ml-1"
                        type="is-success is-light"
                        icon-left="content-copy"
                        label="Tümünü Getir"
                        @click="stocks_all"
                    />
                    <b-button
                        class="column ml-1"
                        type="is-info is-light"
                        icon-left="eye"
                        label="Görüntüle"
                        :disabled="!stocks.selected.id"
                        @click="stocks_show"
                    />
                    <b-button
                        class="column ml-1"
                        type="is-warning is-light"
                        icon-left="pencil"
                        label="Düzenle"
                        :disabled="!stocks.selected.id"
                        @click="stocks_edit"
                    />
                    <b-button
                        class="column ml-1"
                        type="is-danger is-light"
                        icon-left="delete"
                        label="Sil"
                        :disabled="!stocks.selected.id"
                        @click="stocks_delete"
                    />
                    <b-button
                        class="column ml-1"
                        type="is-link is-light"
                        icon-left="printer"
                        label="Yazdır"
                        @click="stocks_print"
                        disabled
                    />
                    <b-button
                        class="column ml-1"
                        type="is-link is-light"
                        icon-left="import"
                        label="Içe Aktar"
                        disabled
                    />
                    <export-excel :data="stocks.data" :fields="stocks.fields">
                        <b-button
                            class="column ml-1"
                            type="is-link is-light"
                            icon-left="export"
                            label="Dışa Aktar"
                        />
                    </export-excel>
                </b-field>

                <b-table
                    class="column is-12"
                    :mobile-cards="false"
                    :data="stocks.data"
                    :total="stocks.total"
                    :current-page.sync="stocks.page"
                    :selected.sync="stocks.selected"
                    :sort-multiple-data.sync="stocks.sorts"
                    :loading="$apollo.queries.readMaterialStocks.loading"
                    @sort="stocks_sort"
                    @sorting-priority-removed="stocks_nosort"
                    backend-pagination
                    backend-sorting
                    sort-multiple
                    striped
                    hoverable
                    narrowed
                    paginated
                    detailed
                    custom-detail-row
                    pagination-simple
                >
                    <template slot="empty">
                        <div class="is-12 has-text-centered">Envanter kaydı bulunamadı.</div>
                    </template>

                    <template slot="detail" slot-scope="props">
                        <tr>
                            <th></th>
                            <th></th>
                            <th>Stok ID</th>
                            <th>Parti No</th>
                            <th>Miktar (KG)</th>
                            <th></th>
                        </tr>
                        <tr v-for="lot in props.row.lots" :key="lot.id">
                            <td></td>
                            <td></td>
                            <td>{{ lot.id.slice(-5) }}</td>
                            <td>{{ lot.lotnum }}</td>
                            <td>{{ lot.quantity }} KG</td>
                            <td></td>
                        </tr>
                    </template>

                    <b-table-column field="material.class" label="Sınıf" v-slot="props">{{
                        props.row.material.class
                    }}</b-table-column>
                    <b-table-column field="material.code" label="Malzeme Kodu" v-slot="props">{{
                        props.row.material.code
                    }}</b-table-column>
                    <b-table-column
                        field="material.name"
                        label="Malzeme Açıklaması"
                        v-slot="props"
                        >{{ props.row.material.name }}</b-table-column
                    >
                    <b-table-column field="material.maingroup" label="Ana Grup" v-slot="props">{{
                        props.row.material.maingroup
                    }}</b-table-column>
                    <b-table-column field="material.subgroup" label="Alt Grup" v-slot="props">{{
                        props.row.material.subgroup
                    }}</b-table-column>
                    <b-table-column field="quantity" label="Stok" v-slot="props" numeric sortable
                        >{{ props.row.quantity }} KG</b-table-column
                    >
                </b-table>
            </b-field>
        </div>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "StockTable",
    components: {
        TitleBar,
    },
    data: () => ({
        modi: "",
        stocks: {
            page: 1,
            take: 20,
            total: 9999,
            data: [],
            sorts: [],
            filters: {
                material: {},
            },
            selected: {},
            fields: {
                ID: "id",
                "Malzeme ID": "material.id",
                "Malzeme Sınıfı": "material.class",
                "Malzeme Ana Grup": "material.maingroup",
                "Malzeme Alt Grup": "material.subgroup",
                "Malzeme Kodu": "material.code",
                "Malzeme Adı": "material.name",
                Miktar: "quantity",
            },
        },
    }),
    mounted() {
        this.stocks_read();
    },
    methods: {
        stocks_read() {
            this.$apollo.queries.readMaterialStocks.start();
            this.$apollo.queries.readMaterialStocks.refetch();
        },
        stocks_new() {
            this.$router.push({ path: "/malzeme/ekle" });
        },
        stocks_all() {
            this.stocks.take = 9999;
        },
        stocks_show() {
            this.$router.push({
                path: "/malzeme/detay",
                params: { id: this.stocks.selected.id, modi: "show" },
            });
        },
        stocks_edit() {
            this.$router.push({
                path: "/malzeme/detay",
                params: { id: this.stocks.selected.id, modi: "edit" },
            });
        },
        stocks_delete() {},
        stocks_print() {},
        stocks_export() {},
        stocks_sort(field, order) {
            this.stocks_nosort(field);
            this.stocks.sorts.push({ field, order });
        },
        stocks_nosort(field) {
            this.stocks.sorts.forEach((e, i, a) => {
                if (e.field == field) a.splice(i, 1);
            });
        },
    },
    apollo: {
        readMaterialStocks: {
            query: gql`
                query readMaterialStocks($config: String) {
                    readMaterialStocks(config: $config) {
                        id
                        lots {
                            id
                            lotnum
                            quantity
                        }
                        material {
                            class
                            code
                            name
                            maingroup
                            subgroup
                        }
                        quantity
                    }
                }
            `,
            variables() {
                var sorts = [];
                this.stocks.sorts.forEach((e) => sorts.push({ column: e.field, order: e.order }));
                for (var filter in this.stocks.filters)
                    if (!this.stocks.filters[filter]) delete this.stocks.filters[filter];

                return {
                    config: JSON.stringify({
                        sorts,
                        take: this.stocks.take,
                        filters: this.stocks.filters,
                        skip: (this.stocks.page - 1) * this.stocks.take,
                    }),
                };
            },
            update(data) {
                this.stocks.data = data.readMaterialStocks;
            },
        },
    },
};
</script>
